import React from "react";
import { Container } from "react-bootstrap";
import Navigation from "../navbar/Navigation";
import "./privacy.css";
import withRouter from "../withRouter";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";


function Privacy() {
  return (
    <>
      <div className="banner">
        <Navigation />
        <Container className="mt-lg-5 mt-md-5 text-white">
        <h1>Privacy Policy</h1>
    <p><strong>Effective Date:</strong> 18 November 2024</p>

    <p>B2Play (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information when you use the B2Play App (the “App”). By using the App, you agree to the practices described in this Privacy Policy.</p>

    <h2>1. Information We Collect</h2>

    <h3>1.1. Information You Provide to Us</h3>
    <ul>
        <li><strong>Account Information:</strong> When you create an account, we may collect your name, email address, phone number, and other profile details.</li>
        <li><strong>Payment Information:</strong> If you make a purchase through the App, we may collect billing details (e.g., credit card information) through secure third-party payment processors.</li>
        <li><strong>Support Requests:</strong> When you contact support, we may collect details of your inquiry and any additional information you provide.</li>
    </ul>

    <h3>1.2. Information Collected Automatically</h3>
    <ul>
        <li><strong>Device Information:</strong> We may collect details such as your device model, operating system, and unique device identifiers.</li>
        <li><strong>Usage Data:</strong> Information about how you interact with the App, such as features used, session duration, and crash logs.</li>
        <li><strong>Location Data:</strong> With your permission, we may collect your approximate or precise location to enhance app functionality.</li>
    </ul>

    <h3>1.3. Information from Third Parties</h3>
    <p>We may collect data from third-party sources, such as social networks, when you link your accounts or use third-party login services.</p>

    <h2>2. How We Use Your Information</h2>
    <ul>
        <li>To operate, maintain, and improve the App’s features and performance.</li>
        <li>To personalize your experience and provide tailored content.</li>
        <li>To process transactions and send confirmations.</li>
        <li>To provide customer support and respond to your inquiries.</li>
        <li>To send you updates, promotions, or notifications about the App, if permitted by law.</li>
        <li>To comply with legal obligations and enforce our Terms of Service.</li>
    </ul>

    <h2>3. Sharing Your Information</h2>
    <ul>
        <li><strong>With Service Providers:</strong> We may share information with third-party vendors who help us operate the App (e.g., hosting, analytics, and payment processors).</li>
        <li><strong>For Legal Compliance:</strong> We may disclose your information to comply with legal obligations or respond to lawful requests from authorities.</li>
        <li><strong>With Your Consent:</strong> We may share information with third parties when you provide explicit consent.</li>
    </ul>

    <h2>4. Data Retention</h2>
    <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>

    <h2>5. Your Rights and Choices</h2>
    <ul>
        <li><strong>Access and Update:</strong> You may access and update your personal information through the App.</li>
        <li><strong>Opt-Out:</strong> You can opt out of promotional communications by following the unsubscribe link in our emails.</li>
        <li><strong>Data Deletion:</strong> You may request the deletion of your personal data, subject to legal requirements.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="mailto:info@b2playapp.com">info@b2playapp.com</a>.</p>

    <h2>6. Security</h2>
    <p>We implement appropriate technical and organizational measures to protect your information from unauthorized access, loss, or misuse. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>

    <h2>7. Children’s Privacy</h2>
    <p>The App is not intended for users under the age of 13, and we do not knowingly collect personal information from children.</p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy periodically. Changes will be effective upon posting the revised policy in the App. We encourage you to review this policy regularly.</p>

    <h2>9. Contact Us</h2>
    <p>If you have questions or concerns about this Privacy Policy, please contact us at:</p>
    <ul>
        <li><strong>Email:</strong> <a href="mailto:info@b2playapp.com">info@b2playapp.com</a></li>
        <li><strong>Address:</strong> Budafoki 183, 1117, Budapest, Hungary</li>
    </ul>

    <h2>10. Additional Information for Google Play Users</h2>
    <ul>
        <li>The App does not collect sensitive information without explicit user consent.</li>
        <li>The App respects Android’s permission system and will only access features like location, camera, or storage when granted permission.</li>
    </ul>
        </Container>
      </div>
    </>
  );
}

Privacy.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Privacy));
